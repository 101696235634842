import React from "react"

import { exitTransition, entryTransition} from '../components/transition/transition'
import TransitionLink from 'gatsby-plugin-transition-link'

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

const Page404 = () => (
  <Layout className="page__category" >
    <SEO title="Home" />

    <h2>
      <TransitionLink 
      to="/"
      exit={exitTransition}
      entry={entryTransition}
      dangerouslySetInnerHTML={{__html: "This page does not exist. Click to return to home" }}
      />
    </h2>
  </Layout>
)

export default Page404
